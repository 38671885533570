<template lang="pug">
    v-container.custom-container()
        v-layout(column align-center)
            v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
                v-img( cover max-height='300px' max-width='600px' :src="image")
                .title.mt-2(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
                v-divider.primary.mt-5( style="width: 100px;")
            v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3
                p.py-2.text-justify
                  h2.mr-2 Careers
                  | Working at Scene Finder is an opportunity to grow and be a part of a team. This is an environment where you can be yourself, have a voice by bring your creative ideas to the platform and grow with us.
                v-dialog(v-model="dialog" width="500")
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(color="primary" v-bind="attrs" v-on="on").my-4
                      | Check available positions on Careers
                  v-card
                    v-card-title.headline.grey.lighten-2(primary-title="")
                      | Available Positions
                    v-card-text
                      | No available positions at this time, please check later
                    v-divider
                    v-card-actions
                      v-spacer
                      v-btn(color="primary" text="" @click="dialog = false")
                        | Ok

</template>

<script>
  import image from '@/assets/images/static/careers.jpeg'
  export default {
    data () {
      return {
        title: 'Careers',
        dialog: false,
        image: image,
      }
    },

    computed: {

    },

  }
</script>

<style lang='scss' scoped>

</style>
